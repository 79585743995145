.externalAuthentication {
  background: #ffffff;
  display: block;
  padding: 8px;
  box-sizing: border-box;
  color: #000;
  height: 100vh;

  div {
    display: block;
    text-align: center;
    text-align: -webkit-center;
  }

  h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 21px;
  }

  hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
  }
}
