@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif', serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

html, body, :global(#root) {
  width: 100%;
  min-height: 100%;
  color: #425363;
}

button {
  border: none;
  background: none;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
