@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
*{margin:0;padding:0;box-sizing:border-box;outline:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif', serif;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-overflow-scrolling:touch}html,body,#root{width:100%;min-height:100%;color:#425363}button{border:none;background:none}button:focus{outline:none}button:hover{cursor:pointer}

.Login_login__10IcO{display:flex;justify-content:center;align-items:center;height:100vh;background:#efefef}.Login_login__10IcO .Login_form__1tdal{width:300px;display:flex;flex-direction:column;padding:20px;box-shadow:0 15px 45px rgba(0,0,0,0.2);background:#fff;border-radius:4px}.Login_login__10IcO .Login_form__1tdal .Login_header__26DqL{display:flex;justify-content:center;align-items:center;flex-direction:column;background:url(/static/media/teamwork.8a09889e.gif) no-repeat center;background-size:60%;margin-bottom:10px;height:100px}.Login_login__10IcO .Login_form__1tdal .Login_input__2EuP6{width:100%;margin-bottom:10px}.Login_login__10IcO .Login_form__1tdal .Login_actions__SP10E{display:flex;justify-content:flex-end}.Login_login__10IcO .Login_form__1tdal .Login_actions__SP10E .Login_submit__3cWT7{width:100%;border-radius:4px;color:#fff;border:none;height:42px;background:#396eff;position:relative;padding:10px;font-size:14px;box-shadow:0 3px 6px rgba(103,157,255,0.2);-webkit-transition:box-shadow 0.3s ease-in-out;transition:box-shadow 0.3s ease-in-out}.Login_login__10IcO .Login_form__1tdal .Login_actions__SP10E .Login_submit__3cWT7:hover{box-shadow:0 6px 12px rgba(103,157,255,0.4)}.Login_login__10IcO .Login_form__1tdal .Login_actions__SP10E .Login_submit__3cWT7.Login_isLoading__1Cwj3{opacity:0.5}

.InputField_inputField__3HdAk{display:flex;overflow:hidden;padding-left:5px;position:relative;box-sizing:border-box;width:100%;border-radius:4px;margin-bottom:8px;background:#fafafa;-webkit-transition:background .3s ease-in-out, box-shadow .3s ease-in-out;transition:background .3s ease-in-out, box-shadow .3s ease-in-out}.InputField_inputField__3HdAk.InputField_isFocus__1yTzu{background:#efefef}.InputField_inputField__3HdAk .InputField_iconWrapper__31fTl{color:#396eff;width:32px;height:38px;position:relative}.InputField_inputField__3HdAk .InputField_iconWrapper__31fTl svg{position:absolute;width:20px;height:20px;left:0;top:0;right:0;bottom:0;margin:auto}.InputField_inputField__3HdAk .InputField_input__3cvMf{border:none;padding:5px;background:none;width:calc(100% - 32px);height:38px;box-sizing:border-box;outline:none;font-size:14px}


.ToastMessageItem_toastMessageItem__3wULh{position:fixed;right:25px;bottom:25px;z-index:999;background:#ffffff;box-shadow:0 15px 45px rgba(0,0,0,0.2);padding:10px;font-size:15px;border-radius:2px}.ToastMessageItem_toastMessageItem__3wULh.ToastMessageItem_isSuccess__13RSS{color:#fff;background:#3ae374}.ToastMessageItem_toastMessageItem__3wULh.ToastMessageItem_isWarning__28hFb{color:#fff;background:#ffaf40}.ToastMessageItem_toastMessageItem__3wULh.ToastMessageItem_isError__1uR9n{color:#fff;background:#ff4d4d}

.ExternalAuthentication_externalAuthentication__25ykT{background:#ffffff;display:block;padding:8px;box-sizing:border-box;color:#000;height:100vh}.ExternalAuthentication_externalAuthentication__25ykT div{display:block;text-align:center;text-align:-webkit-center}.ExternalAuthentication_externalAuthentication__25ykT h1{display:block;font-size:2em;font-weight:bold;margin-bottom:21px}.ExternalAuthentication_externalAuthentication__25ykT hr{display:block;unicode-bidi:-webkit-isolate;unicode-bidi:isolate;-webkit-margin-before:0.5em;margin-block-start:0.5em;-webkit-margin-after:0.5em;margin-block-end:0.5em;-webkit-margin-start:auto;margin-inline-start:auto;-webkit-margin-end:auto;margin-inline-end:auto;overflow:hidden;border-style:inset;border-width:1px}

